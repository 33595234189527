@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Regular.ttf');
  src: local('Plus Jakarta Sans Regular'),
    local('PlusJakartaSans-Regular'),
    url('../fonts/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Italic.ttf');
  src: local('Plus Jakarta Sans Italic'),
    local('PlusJakartaSans-Italic'),
    url('../fonts/PlusJakartaSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-ExtraBold.ttf');
  src: local('Plus Jakarta Sans ExtraBold'),
    local('PlusJakartaSans-ExtraBold'),
    url('../fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-ExtraBoldItalic.ttf');
  src: local('Plus Jakarta Sans ExtraBold Italic'),
    local('PlusJakartaSans-ExtraBoldItalic'),
    url('../fonts/PlusJakartaSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Light.ttf');
  src: local('Plus Jakarta Sans Light'),
    local('PlusJakartaSans-Light'),
    url('../fonts/PlusJakartaSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-LightItalic.ttf');
  src: local('Plus Jakarta Sans Light Italic'),
    local('PlusJakartaSans-LightItalic'),
    url('../fonts/PlusJakartaSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Bold.ttf');
  src: local('Plus Jakarta Sans Bold'),
    local('PlusJakartaSans-Bold'),
    url('../fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-BoldItalic.ttf');
  src: local('Plus Jakarta Sans Bold Italic'),
    local('PlusJakartaSans-BoldItalic'),
    url('../fonts/PlusJakartaSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-Medium.ttf');
  src: local('Plus Jakarta Sans Medium'),
    local('PlusJakartaSans-Medium'),
    url('../fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-MediumItalic.ttf');
  src: local('Plus Jakarta Sans Medium Italic'),
    local('PlusJakartaSans-MediumItalic'),
    url('../fonts/PlusJakartaSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-ExtraLight.ttf');
  src: local('Plus Jakarta Sans ExtraLight'),
    local('PlusJakartaSans-ExtraLight'),
    url('../fonts/PlusJakartaSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Plus Jakarta Sans';
  src: url('../fonts/PlusJakartaSans-ExtraLightItalic.ttf');
  src: local('Plus Jakarta Sans ExtraLight Italic'),
    local('PlusJakartaSans-ExtraLightItalic'),
    url('../fonts/PlusJakartaSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}




@font-face {
  font-display: swap;
  font-family: 'Libre Baskerville';
  src: url('../fonts/LibreBaskerville-Regular.ttf');
  src: local('Libre Baskerville Regular'),
    local('LibreBaskerville-Regular'),
    url('../fonts/LibreBaskerville-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Libre Baskerville';
  src: url('../fonts/LibreBaskerville-Italic.ttf');
  src: local('Libre Baskerville Italic'),
    local('LibreBaskerville-Italic'),
    url('../fonts/LibreBaskerville-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-display: swap;
  font-family: 'Libre Baskerville';
  src: url('../fonts/LibreBaskerville-Bold.ttf');
  src: local('Libre Baskerville Bold'),
    local('LibreBaskerville-Bold'),
    url('../fonts/LibreBaskerville-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}