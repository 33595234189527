@import './colors';
@import './mixins';

.mout {
  margin-bottom: 120px;

  &.mout--home {
    margin-bottom: 68px;

    @include bp(mobile) {
      margin-bottom: 50px;
    }
  }

  .mout-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 32px;
    color: $TextColor;

    @include bp(mobile) {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
    }

    &.mout-title-margin-bootom {
      margin-bottom: 64px;
    }
  }

 

  .mout-text {
    font-size: 24px;
    line-height: 42px;
    text-align: center;
    color: #243447;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 25px;

    @include bp(mobile) {
      width: auto;
      font-size: 14px;
      line-height: 173.7%;
      margin-bottom: 10px;
    }
  }

  .mout-photos {
    display: grid;
    grid-template-columns: repeat(6, 177px);
    justify-content: center;
    gap: 24px;
    margin-bottom: 164px;

    img{
      width: 177px;
      height: 177px;

      @include bp(tablet) {
        width: 154px;
        height: 154px;
      }
  
      @include bp(mobile) {
        width: 140px;
        height: 140px;
      }
    }

    @include bp(tablet) {
      grid-template-columns: repeat(4, 154px);
      margin-bottom: 100px;
      gap: 16px;
    }

    @include bp(mobile) {
      grid-template-columns: repeat(2, 140px);
      margin-bottom: 40px;
      gap: 16px;
    }

    .person{
      background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 71.08%);
      filter: drop-shadow(2px 4px 34px rgba(0, 0, 0, 0.09));
      padding: 16px;

      @include bp(tablet) {
        padding: 12px;
      }

      @include bp(mobile) {
        width: 156px;
        padding: 12px;
      }

      .details{
        padding-top: 12px;

        h5{
          font-size: 14px;
          line-height: 18px;
          padding-bottom: 6px;
        }

        p{
          color: $grGrayColor;
          font-size: 14px;
          line-height: 18px;
        }
      }

      img {
        display: block;
        width: 145px;
        height: 145px;

        @include bp(tablet) {
          width: 130px;
          height: 130px;
        }
  
        @include bp(mobile) {
          width: 132px;
          height: 132px;
        }
      }
    }
  }

  .mout-brands {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px 20px;
    align-items: center;

    &.mout-brands--home {
      padding-top: 30px;
      padding-bottom: 0px;

      @include bp(mobile) {
        padding-top: 38px;
      }

      .mout-brands-image {
        margin: 16px 0;

        @include bp(tablet){
          &:last-child{
            margin: auto;
          }
        }

        @include bp(mobile) {
          margin: 11px 0;
          
          &:last-child{
            margin: auto;
          }
        }
      }
    }

    // &:after {
    //   @include bp(tablet) {
    //     content: '';
    //     width: 190px;
    //     margin: 10px 0;
    //   }
    // }
  }

  .mout-brands-image {
    width: 190px;
    text-align: center;
    margin: 12px 0;
    box-sizing: border-box;

    img {
      max-width: 100%;
    }

    @include bp(tablet){
      &:last-child{
        margin: auto;
      }
    }

    @include bp(mobile) {
      width: 148px;
      margin: 10px 0;

      &:last-child{
        margin: auto;
      }
    }
  }
}

.mout--case{
  margin-bottom: 0px;
}
