@import './reset';
@import './colors';
@import './mixins';
@import './fonts';
@import './body';
@import './button';
@import './input';
@import './text-styles';
@import './container';
@import './logo';
@import './card';
// @import './header';
@import './light-section';
@import './cards';
@import './green-section';
@import './green-section-v2';
@import './footer';
@import './modals';
@import './meet-on-our-team';
@import './top-background';
@import './modal';


html {
  scroll-behavior: smooth;
}

.hide-ss {
  @include bp(mobile) {
    display: none;
  }

  @include bp(tablet) {
    display: none;
  }
}



.hide-bs {
  display: none !important;

  @include bp(mobile) {
    display: block !important;
  }

  @include bp(tablet) {
    display: block !important;
  }
}









body {
  margin: 0;
  padding: 0;
  position: relative;
}

// START HEADER

.header-container {
  max-width: 1200px;
  margin: 0 auto;

  @include bp(mobile) {
    padding: 0 16px;
    max-width: 100%;
  }

  @include bp(tablet) {
    padding: 0 32px;
  }
}

.header {
  height: 80px;
  margin-bottom: 0px;
  background: #FFF;
  border-bottom: 1px solid #EDEDED;

  @include bp(mobile) {
    height: 80px;
  }

  .sticky-header {
    &--sticky {
      height: 80px;
      background: #FFF;
      position: fixed;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(34, 60, 80, .2);
      z-index: 5;
    }
  }

  .header-menu-button {
    border: none;
    display: block;
    padding: 0;
    width: 32px;
    height: 32px;
    background: url('../images/icons/menu.png') no-repeat center;
    background-size: contain;
    cursor: pointer;
    display: none;

    @include bp(mobile) {
      display: block;
    }
  }

  .header-menu {
    display: flex;
    gap: 26px;

    .button {
      width: 255px;
      height: 60px;
      padding: 8px 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border-color: #101828;

      &:hover {
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
      }

      @include bp(mobile) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .header-logo {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  }

  .header-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    // text-transform: uppercase;
    color: #000;
    text-decoration: none;
    margin-left: 26px;

    @include bp(mobile) {
      display: none;
    }

    @include bp(tablet) {
      display: none;
    }
  }
}

.small-screen-menu-close {
  width: 24px;
  height: 24px;
  padding: 24px;
  border: 0;
  background: url('../images/close-icon-white.svg') no-repeat center;
  z-index: 5;
  cursor: pointer;
  color: #1A1A1A;
  margin-right: -7px;
}

.small-screen-menu {
  display: none;

  @include bp(mobile) {
    position: relative;

    .small-header-section {
      border-bottom: 1px solid #DEDEDE;
      position: absolute;
      top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0px 16px;
    }

    .header-logo {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--open {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0px;
      left: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      z-index: 1000;
      background: #FFF;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
    }

    .header-menu-link {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      // text-transform: uppercase;
      color: #1A1A1A;
      text-decoration: none;
    }

    .header-menu-actions {
      display: flex;
      flex-direction: column;
      justify-self: flex-end;
      align-self: flex-end;
      width: 100%;
      padding: 0 24px;
      gap: 12px;

      position: absolute;
      top: 188px;

      .button {
        width: 100%;
        height: 60px;
        padding: 8px 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-color: #101828;

        &:hover {
          box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

    }

    .header-menu-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      box-sizing: border-box;
      padding-bottom: 80px;
      padding: 0 24px;

      position: absolute;
      top: 113px;
    }

    .header-menu-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 80px;

      img {
        width: 34px;
        height: 34px;
        object-fit: contain;
      }
    }
  }
}

// END HEADER


.hero {
  background: url('../images/bg/hero.png') no-repeat center center/cover;
  height: 560px;
  width: 100%;
  display: flex;
  align-items: center;

  @include bp(mobile) {
    background: url('../images/bg/hero-mobile.png') no-repeat center center/cover;
    height: 486px;
    padding: 0 16px;
  }

  .hero-content {
    max-width: 1000px;
    margin: 0 auto;
    color: white;
    text-align: center;

    @include bp(mobile) {
      width: 100%;
    }

    h1 {
      font-size: 62px;
      font-weight: 400;
      color: white;
      margin-bottom: 24px;

      @include bp(mobile) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 18px;

        span {
          font-size: 45px;
        }
      }
    }

    p {
      font-size: 1.2em;
      color: #B6B6B6;
      margin-bottom: 64px;
      line-height: 32px;

      @include bp(mobile) {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 32px;
      }
    }

    button {
      color: white;
      padding: 20px 28px;
      border: none;
      cursor: pointer;
      font-size: 18px;
      font-weight: 400;
      display: none;

      &:hover {
        background: #2880bb;
      }

      @include bp(mobile) {
        display: inline-flex;
      }
    }
  }
}

section {
  text-align: center;

  &.empowering-brand-story {
    padding: 120px 0;

    @include bp(mobile) {
      padding: 64px 16px;
    }

    // background: #f8f9fa;
    h2 {
      font-size: 62px;
      font-weight: 400;
      margin-bottom: 60px;

      @include bp(mobile) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
      }

      @include bp(tablet) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
      }
    }

    >p {
      width: 900px;
      margin: 0 auto;
      font-size: 20px;

      @include bp(mobile) {
        font-size: 16px;
        width: 100%;
      }

      @include bp(tablet) {
        font-size: 24px;
        line-height: 38px;
        width: 100%;
      }
    }

    .stats {
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 48px;
      margin-bottom: 0px;
      gap: 64px;

      @include bp(mobile) {
        flex-direction: column;
        align-items: center;
        margin-top: 48px;
        margin-bottom: 0px;
        gap: 16px;
      }

      @include bp(tablet) {
        margin-top: 48px;
        margin-bottom: 48px;
        gap: 16px;
      }

      div {
        text-align: center;
        width: 264px;

        @include bp(mobile) {
          width: 46%;
          margin-bottom: 32px;
        }

        @include bp(tablet) {
          width: 46%;
          margin-bottom: 32px;
        }

        strong {
          display: block;
          font-size: 60px;
          font-weight: 500;
          color: $PrimaryColor;
          font-family: 'Libre Baskerville', sans-serif;
          margin-bottom: 18px;

          @include bp(mobile) {
            margin-bottom: 24px;
            font-size: 48px;
            font-weight: 600;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 18px;
          color: $TextHeading;
          font-weight: 500;
        }
      }
    }
  }

  &.tribe-brands {
    padding: 0px 0 80px 0;

    @include bp(mobile) {
      padding: 0px 0px 64px 0px;
    }

    h2 {
      font-size: 62px;
      font-weight: 400;
      margin-bottom: 64px;

      @include bp(mobile) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
      }
    }

    .platform-flex {
      display: flex;
      justify-content: center;

      @include bp(mobile) {
        flex-direction: column;
        gap: 40px;
      }

      @include bp(tablet) {
        flex-direction: column;
        gap: 40px;
      }

      .platform-item {
        width: 30%;
        background-color: #EFEFEF;
        padding: 37px 36px;
        margin: 0 16px;
        border: 1px solid #FFF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp(mobile) {
          width: 100%;
          margin: 0;
        }

        @include bp(tablet) {
          width: 100%;
          margin: 0;
        }

        &.platform-item-left {
          width: 70%;

          @include bp(mobile) {
            width: 100%;
          }

          @include bp(tablet) {
            width: 100%;
          }
        }

        >img {
          height: 60px;
          object-fit: contain;
        }

        .psg-logo {
          height: 50px;
        }

        h3 {
          font-size: 24px;
          font-weight: 400;
          margin-top: 24px;
        }

        p {
          font-size: 18px;
          color: #707683;
          margin-top: 16px;
        }

        .brand-sections {
          display: flex;
          justify-content: center;
          gap: 36px;
          margin-top: 26px;

          @include bp(mobile) {
            flex-direction: column;
            gap: 20px;
          }
        }

        .social-links {
          border-radius: 10px;
          // padding: 14px 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          margin-top: 40px;

          >img {
            height: 32px;
          }
        }

        .divider {
          width: 60%;
          height: 1px;
          background: #000;
        }
      }
    }



    .brands-logos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin-top: 16px;

      img {
        width: 208px;

        @include bp(tablet) {
          width: 194px;
        }

        @include bp(mobile) {
          width: 150px;
        }
      }

      @include bp(tablet) {
        gap: 16px;
      }

      @include bp(mobile) {
        justify-content: center;
        gap: 0;
        flex-direction: row;
      }


    }
  }

  &.structure {
    margin: 120px 0 120px 0;

    @include bp(mobile) {
      margin: 64px 0px 64px 0px;
    }

    h2 {
      font-size: 62px;
      font-weight: 400;
      margin-bottom: 64px;

      @include bp(mobile) {
        font-size: 31px;
        line-height: 48px;
        margin-bottom: 40px;
      }
    }

    .structure-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 64px;



      @include bp(mobile) {
        gap: 10px;
        width: 100%;
      }

      @include bp(tablet) {
        width: 100%;
        gap: 16px;
      }

      .structure-item {
        width: 30%;
        // padding: 37px 36px;
        // margin: 0 16px;
        border: 1px solid #FFF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include bp(mobile) {
          width: 48%;
          margin: 0;
        }

        @include bp(tablet) {
          width: 48%;
          margin: 0;
        }

        >img {
          height: 100%;
          width: 100%;
          object-fit: cover;

          @include bp(mobile) {
            object-fit: contain;
            height: 163px;
            width: 163px;
          }
        }

        h4 {
          font-size: 30px;
          font-weight: 400;
          margin-top: 20px;
          font-family: 'Libre Baskerville', sans-serif;
          text-transform: uppercase;
          text-align: left;


          @include bp(mobile) {
            font-size: 18px;
            margin-top: 9px;
          }
        }

        p {
          font-size: 18px;
          color: #101828;
          margin-top: 12px;
          font-weight: 400;
          text-transform: uppercase;
          text-align: left;
          width: 90%;

          @include bp(mobile) {
            font-size: 12px;
            margin-top: 5px;
            text-align: left;
          }
        }
      }

      &.board {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 0 auto;

        @include bp(mobile) {
          justify-content: space-between;
          margin: 0;
          max-width: 100%;
          width: 100%;
          gap: 10px;
        }

        @include bp(tablet) {
          width: 100%;
        }

        .structure-item {
          width: 32%;

          @include bp(mobile) {
            width: 48%;
          }

          @include bp(tablet) {
            width: 48%;
          }

        }
      }
    }
  }

  &.features {
    margin-top: 30px;
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    gap: 90px;

    @include bp(mobile) {
      padding: 0 16px;
      gap: 40px;
      margin-bottom: 64px;
    }

    h2.features-heading {
      font-size: 62px;
      font-weight: 400;
      margin-bottom: 20px;
      margin-top: 20px;

      @include bp(mobile) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
      }

      @include bp(tablet) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
      }
    }

    .summary {
      margin: 16px 0 40px 0;
    }

    h3 {
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-weight: 700;
      font-size: 18px;
    }

    .tiers {
      margin-top: 40px;

      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 56px;

        @include bp(mobile) {
          text-align: left;
        }

        &:before {
          content: '';
          width: 28px;
          height: 28px;
          background: url('../images/icons/check.png') no-repeat center;
          background-size: contain;
          position: absolute;
          left: 16px;
          color: #707683;
        }
      }
    }

    .features-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1em;
      text-align: left;
      gap: 60px;
      margin-left: auto;
      margin-right: auto;

      @include bp(mobile) {
        flex-direction: column-reverse;
        gap: 30px;
      }

      @include bp(tablet) {
        flex-direction: column-reverse;
        gap: 30px;
      }

      >div {
        // background: #e9ecef;
        // border: 1px solid #e9ecef;

        &.fcontent {
          margin-left: 180px;
          width: 45%;
          max-width: 940px;


          @include bp(mobile) {
            margin-left: 0;
            width: 100%;
            text-align: center;
          }

          @include bp(tablet) {
            width: 65%;
            margin: 0 auto;
          }

        }

        &.image-wrapper {
          // margin-left: 180px;
          width: 55%;

          @include bp(mobile) {
            width: 100%;
          }

          img {
            width: 100%;
            max-width: 850px;
          }
        }
      }
    }

    .features-flex-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1em;
      text-align: left;
      gap: 40px;
      margin-left: auto;
      margin-right: auto;

      @include bp(mobile) {
        flex-direction: column;
        gap: 40px;
      }

      @include bp(tablet) {
        flex-direction: column;
        gap: 40px;
      }

      &.mobile {
        @include bp(mobile) {
          flex-direction: column-reverse;
        }

        @include bp(tablet) {
          flex-direction: column-reverse;
        }
      }

      >div {
        // background: #e9ecef;
        // border: 1px solid #e9ecef;

        &.fcontent {
          width: 40%;
          min-width: 700px;
          padding-right: 100px;

          @include bp(mobile) {
            margin-left: 0;
            width: 100%;
            min-width: 100%;
            text-align: center;
            padding-right: 0px;
          }

          @include bp(tablet) {
            width: 65%;
            margin: 0 auto;
            min-width: unset;
            padding-right: 0px;
          }
        }

        &.image-wrapper {
          // margin-left: 180px;

          width: 45%;
          display: flex;
          justify-content: center;
          align-items: center;

          @include bp(mobile) {
            width: 100%;
          }

          img {
            width: 60%;
            max-width: 500px;
            object-fit: contain;

            @include bp(mobile) {
              width: 70%;
            }

            // &.img-norm {
            //   width: 55.4%;
            // }
          }

          &.bigger {
            img {
              width: 77.1%;

              @include bp(mobile) {
                width: 70%;
              }
            }
          }
        }

        &.content-right {
          margin-right: 80px;
          margin-left: 0;
          max-width: 1000px;

          @include bp(mobile) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &.who-we-serve {
    padding: 160px 0;

    @include bp(mobile) {
      padding: 64px 0px;
    }

    >h2 {
      margin-bottom: 30px;

      @include bp(mobile) {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 20px;
      }
    }

    >p {
      width: 1280px;
      margin: 0 auto;
      font-size: 20px;
      line-height: 40px;
      margin-bottom: 86px;

      @include bp(mobile) {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 40px;
        padding: 0 16px;
      }

      @include bp(tablet) {
        width: 100%;
        font-size: 18px;
        line-height: 34px;
        margin-bottom: 40px;
        padding: 0 16px;
      }
    }

    .served-industries {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1280px;
      margin: 0 auto;
      margin-top: 1em;
      text-align: left;

      @include bp(tablet) {
        width: 100%;
        flex-wrap: wrap;
        padding: 0 16px;
        justify-content: start;
      }

      &.hide-bs-tab {
        display: none;

        @include bp(mobile) {
          display: flex;
        }
      }

      @include bp(mobile) {
        &.hide-ss {
          display: none;
        }
      }

      .serve-item {
        width: 240px;
        border: 1px solid #EDEDED;
        margin: 0.5em;

        border-radius: 24px;

        @include bp(mobile) {
          width: 70%;
        }

        .serve-content {
          padding: 1em;

          @include bp(mobile) {
            min-height: 175px;
          }
        }

        img {
          width: 100%;
        }

        h4 {
          font-family: 'Plus Jakarta Display', sans-serif;
          font-weight: 700;
          font-size: 18px;
        }

        p {
          font-size: 18px;
          color: #000;
        }
      }
    }
  }

  &.call-to-action {
    margin-bottom: 140px;

    @include bp(mobile) {
      margin-bottom: 56px;
    }

    .cta {
      display: flex;
      justify-content: center;
      align-items: center;

      @include bp(mobile) {
        background-size: cover;
      }

      @include bp(tablet) {
        background-size: cover;
        padding: 36px;
      }

      .cta-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 34px;
        width: 100%;

        @include bp(mobile) {
          gap: 16px;
        }
      }

      h2 {
        font-size: 62px;
        font-weight: 400;

        @include bp(mobile) {
          font-size: 31px;
          line-height: 41.2px;
        }

        @include bp(tablet) {
          font-size: 31px;
          line-height: 41.2px;
        }
      }

      .cta-content-row {
        display: flex;
        justify-content: flex-start;
        gap: 26px;

        @include bp(mobile) {
          flex-direction: column;
          gap: 1em;
          width: 100%;
        }

        @include bp(tablet) {
          flex-direction: column;
          gap: 1em;
        }
      }

      p {
        color: #101828;
        font-size: 20px;
        font-weight: 500;
        max-width: 800px;

        @include bp(mobile) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 32px;
          font-weight: 400;
        }

        @include bp(tablet) {
          font-size: 18px;
          line-height: 24px;
          // margin-bottom: 32px;
          font-weight: 400;
        }
      }

      .button {
        width: 390px;
        height: 92px;
        padding: 16px 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-color: #101828;

        &:hover {
          box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
        }

        @include bp(mobile) {
          width: 100%;
          justify-content: center;
          padding: 16px 28px;
          font-size: 18px;
          height: 60px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      // button.button {
      //   background-color: white;
      //   color: #000;
      //   padding: 16px 32px;
      //   display: flex;
      //   align-items: center;
      //   line-height: 0px;

      //   @include bp(tablet) {
      //     justify-content: center;
      //   }

      //   @include bp(mobile) {
      //     justify-content: center;
      //     padding: 16px 0;
      //     font-size: 18px;
      //   }

      //   img {
      //     margin-left: 10px;
      //     height: 28px;
      //   }
      // }
    }
  }
}

.splide__arrows.splide__arrows--ltr,
.splide__pagination.splide__pagination--ltr {
  display: none;
}

footer {
  margin-bottom: 44px;

  @include bp(mobile) {
    margin-bottom: 0px;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #EDEDED;
    margin-top: 20px;
    padding: 32px 0;

    @include bp(mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: #98A2B3;
    }
  }
}