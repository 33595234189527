@import './colors';
@import './mixins';

.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: rgba(16, 26, 21, 0.65);
    z-index: 30;
  
    &.modal-overlay--open {
      display: flex;
    }
}

.modal {
    background: white;
    border-radius: 20px;
    position: relative;
    padding: 24px;
    text-align: center;

    h3{
        padding-bottom: 16px;
        padding-top: 16px;
        max-width: 460px;

        @include bp(tablet) {
            padding-top: 16px;
        }

        @include bp(mobile) {
            padding-bottom: 0px;
            margin: 12px auto;
        }
    }

    @include bp(tablet) {
        padding: 24px;
    }

    @include bp(mobile) {
        width: 90%;
        padding: 16px;
        overflow: scroll;
    }

    .modal__close {
        position: absolute;
        border: none;
        display: block;
        cursor: pointer;
        width: 20px;
        height: 20px;
        // background: url('../images/close-icon.svg') no-repeat center;
        right: 22px;
        top: 18px;
    
        @include bp(mobile) {
          right: 14px;
          top: 14px;
        }
    }

    .form-button-holder{
        .button{
            width: 300px;

            @include bp(mobile) {
                width: 100%;
            }
        }
    }

    .form-terms{
        margin-bottom: 12px;
        p{
            color: #707683;
            font-size: 12px;

            a{
                color: #707683;
            }
        }
    }
}