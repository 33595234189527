@import './colors';
@import './mixins';

.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000, $alpha: .3);
  box-sizing: border-box;
  padding-top: 200px;
  z-index: 999;
  display: none;
}

.message-sent {
  position: relative;
  width: 456px;
  background: #fff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  box-shadow: 5px 7px 9px rgba(0, 0, 0, .07);
  border-radius: 8px;
  margin: 0 auto;
  padding: 50px 32px;

  @include bp(mobile) {
    width: 360px;
  }

  .message-sent-ok-img-holder {
    text-align: center;
    padding-bottom: 32px;
  }

  .message-sent-text {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
  }

  .message-sent-close {
    border: none;
    // background: url('../images/close-dark-icon.svg') no-repeat center;
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}
