@import './colors';
@import './mixins';

.container {
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;

  &--relative {
    position: relative;
    z-index: 1;
  }

  @include bp(mobile) {
    padding: 0 16px;
    width: 100%;
  }

  @include bp(tablet) {
    padding: 0 30px;
    width: 868px;
  }
}