body,
input,
textarea,
button {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

body {
  background: #fdfdff;
}
