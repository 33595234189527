@import './colors';
@import './mixins';

section.light {
  box-sizing: border-box;
  padding: 226px 0 179px;
  margin-bottom: 61px;
  position: relative;

  @include bp(tablet) {
    padding: 160px 0 160px;
  }

  @include bp(mobile) {
    padding: 120px 0 120px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    // background: url('../images/bg/3.svg') repeat-x center;
    height: 499px;
    z-index: 0;

    @include bp(mobile) {
      background-size: 100%;
      background-position: top;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // background: url('../images/bg/4.svg') repeat-x center;
    height: 499px;
    z-index: 0;

    @include bp(mobile) {
      background-size: 100%;
      background-position: bottom;
    }
  }

  .light-inner {
    background: #effffc;
  }

  &.light--off {
    padding: 16px 0 120px;

    @include bp(mobile) {
      padding: 16px 0 0;
    }

    &:after,
    &:before {
      display: none;
    }

    .light-inner {
      background: transparent;
    }
  }

  &.light--mout {
    padding: 161px 0 42px;

    @include bp(mobile) {
      padding: 128px 0 42px;
      margin-bottom: 158px;
    }
  }

  .light-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.light-row--center {
      align-items: center;

      @include bp(mobile) {
        flex-direction: column;
      }
    }

    &.light-row--about-2 {
      @include bp(mobile) {
        flex-direction: column;
      }

      .light-text-col {
        padding-top: 60px;

        @include bp(mobile) {
          padding-top: 0;
        }
      }
    }

    @include bp(mobile) {
      flex-direction: column-reverse;
    }
  }

  .light-text-col {
    width: 530px;

    @include bp(tablet) {
      width: 50%;
    }

    &.light-text-col--text-col-1 {
      width: 391px;
    }

    &.light-text-col--text-col-2 {
      width: 590px;
    }

    @include bp(mobile) {
      width: auto !important;
    }
  }

  .light-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #243447;
    margin-bottom: 18px;

    &.light-title--center {
      text-align: center;
      margin-bottom: 25px;
    }

    &.light-title--about-2 {
      margin-bottom: 25px;

      @include bp(mobile) {
        text-align: center;
      }
    }

    &.light-title--main {
      padding-right: 30px;
      line-height: 1;
    }

    @include bp(mobile) {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;

      &.light-title--main {
        margin-top: 0;
        padding-right: 0;
      }
    }

    @include bp(tablet) {
      font-size: 34px;
      line-height: 1.5;
    }
  }

  .light-text {
    font-size: 18px;
    line-height: 28px;
    color: #243447;
    margin-bottom: 21px;

    @include bp(mobile) {
      font-size: 14px;
      line-height: 28px;
    }

    @include bp(tablet) {
      font-size: 16px;
      line-height: 1.5;
    }

    &.light-text--blockquote {
      font-weight: bold;
      font-size: 47px;
      line-height: 59px;

      p:before {
        content: '“';
        color: #14c1a2;
      }

      p:after {
        content: '”';
        color: #14c1a2;
      }

      @include bp(mobile) {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  .light-button-holder {

    &.light-button-holder--center{
      display: flex;
      justify-content: center;
    }

    @include bp(mobile) {
      text-align: center;
    }
  }

  .light-image-col {
    @include bp(tablet) {
      width: 45%;
    }

    @include bp(mobile) {
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
    }

    .light-button-holder {
      padding-top: 25px;
    }
  }
}
