@import './colors';
@import './mixins';

.top-background {
  height: 775px;
  margin-top: -110px;
  // background: url('../images/top-bg.png') no-repeat center;
  margin-bottom: 40px;

  @include bp(mobile) {
    margin-bottom: 62px;
  }

  @media (min-width: 1441px) {
    // background: url('../images/top-bg@3x.png') no-repeat center;
    background-size: 100% auto;
  }

  @include bp(mobile) {
    height: 570px;
    // background: url('../images/top-bg-mobile.png') no-repeat center;
    background-size: auto 100%;
  }

  .top-background-text {
    padding-top: 233px;
    padding-bottom: 32px;

    @include bp(mobile) {
      padding-top: 140px;
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 72px;
      line-height: 73px;
      text-align: center;
      color: #fff;
      max-width: 820px;
      margin: 0 auto;

      @include bp(mobile) {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }

  .top-background-text-small {
    padding-bottom: 32px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #fff;
      max-width: 470px;
      margin: 0 auto;

      @include bp(mobile) {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }

  .input-inline-holder {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background: #fff;
      border-color: #fff;
      color: #243447;

      &:hover {
        background: rgba(255, 255, 255, .5);
      }
    }

    .input-email {
      border-color: #fff;
      color: #fff;

      @include bp(mobile) {
        margin-right: 0;
      }

      @include placeholder() {
        color: #fff;
      }
    }
  }

  .top-background-terms {
    padding-top: 16px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 1;
      text-align: center;
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}