@import './colors';
@import './mixins';

section.green {
  padding: 214px 0 200px;
  position: relative;
  margin-bottom: 169px;

  @include bp(mobile) {
    padding: 109px 0 120px;
    margin-bottom: 70px;
    margin-top: 50px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    // background: url('../images/bg/1.svg') repeat-x center;
    height: 499px;
    z-index: 0;

    @include bp(mobile) {
      background-size: 100%;
      background-position: top;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // background: url('../images/bg/2.svg') repeat-x center;
    height: 499px;
    z-index: 0;

    @include bp(mobile) {
      background-size: 100%;
      background-position: bottom;
    }
  }

  .green-inner {
    background: #14c1a2;
  }

  .green-title {
    text-align: center;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #fff;
    margin-bottom: 50px;
    margin-top: -71px;

    @include bp(mobile) {
      margin-top: 0;
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
    }

    @include bp(tablet) {
      font-size: 36px;
      line-height: 1.5;
    }
  }

  .green-cards-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @include bp(tablet) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    @include bp(mobile) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .green-button-holder {
    text-align: center;
    margin: 43px 0 -42px;

    @include bp(mobile) {
      margin: 0 0 -42px;
    }
  }
}
