@import './colors';
@import './mixins';

.logo {
  display: inline-block;
  width: 200px;
  height: 76px;
  background: url('../images/logo.png') no-repeat center;
  background-size: contain;
  // border: 1px solid $PrimaryColor;
}

// .home-page {

//   .header {

//     .sticky-header:not(.sticky-header--sticky) {

//       .logo {
//         display: inline-block;
//         width: 119px;
//         height: 18px;
//         background: url('../images/logo-white.svg') no-repeat center;
//       }
//     }
//   }
// }