@import './colors';
@import './mixins';

.cards {
  margin-bottom: 90px;

  @include bp(mobile) {
    margin-bottom: 64px;
  }

  .cards-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #192a3e;
    margin-bottom: 47px;

    @include bp(tablet) {
      font-size: 36px;
      line-height: 1.5;
    }

    @include bp(mobile) {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 25px;
    }
  }

  .cards-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 64px;

    @include bp(tablet) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    @include bp(mobile) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;
      align-items: center;
    }
  }

  .cards-button-holder {
    margin-top: 32px;
    text-align: center;
  }
}
