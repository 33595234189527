@mixin placeholder {

  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin bp($point) {
  @if $point==large {
    @media (min-width: 1366px) {
      @content;
    }
  }

  @if $point==tablet {
    @media (min-width: 768px) and (max-width: 1365px) {
      @content;
    }
  }

  @if $point==subtablet {
    @media (min-width: 768px) and (max-width: 980px) {
      @content;
    }
  }


  @else if $point==mobile {
    @media (max-width: 767px) {
      @content;
    }
  }
}

$retina: 'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)';

@mixin retina-background-image($file, $type, $width, $height) {
  background-image: url($file + '@1x.' + $type);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: $width $height;

  @media #{$retina} {
    background-image: url($file + '@2x.' + $type);
  }
}