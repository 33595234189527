@import './colors';
@import './mixins';

.card {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  width: 353px;
  box-sizing: content-box;
  overflow: hidden;

  &--margin {
    margin-bottom: 20px;
  }

  @include bp(mobile) {
    width: 100%;
    margin-bottom: 20px;
  }

  @include bp(tablet) {
    width: 328px;
    margin-bottom: 20px;
  }

  .card-image {
    margin-bottom: 20px;

    img {
      display: block;
    }

    &.card-image--full {
      margin-bottom: 0;

      img {
        width: 100%;
      }
    }

    &.card-image--icon {
      padding-top: 66px;
      text-align: center;
      margin-bottom: 40px;

      img {
        display: inline;
      }
    }
  }

  .card-inner {
    padding: 0 16px 15px;

    &.card-inner--full {
      padding: 15px;
    }
  }

  .card-logo {
    margin-bottom: 7px;

    img {
      margin-bottom: 4px;
    }

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: $PrimaryColor;
    }
  }

  .card-client-header {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    &.card-client-header--no-padding {
      padding: 0;
    }

    .card-client-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 116.2%;
      color: #243447;
    }

    .card-client-type {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #243447;
    }
  }

  .card-text {
    font-size: 14px;
    line-height: 30px;
    color: #192a3e;
    margin-bottom: 10px;

    &.card-text--mobile {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #707683;
    }

    &.card-text--big {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      b {
        font-weight: 500;
      }
    }

    span {
      color: #14c1a2;
    }

    b {
      font-weight: bold;
    }
  }

  .card-link {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: $PrimaryColor;
    text-decoration: none;
    display: inline-block;
    position: relative;

    &:after {
      transition: all .2s ease-out;
      content: '';
      width: 68px;
      height: 15px;
      // background: url('../images/link-long-arrow.svg') no-repeat center;
      display: inline-block;
      position: absolute;
      top: 8px;
      right: -80px;
    }

    &:hover {

      &:after {
        transform: translateX(5px);
      }
    }
  }

  .card-icon {
    margin-bottom: 24px;
    height: 64px;
    display: flex;
    align-items: center;
  }

  .card-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #243447;
    margin-bottom: 16px;

    &.card-title--no-margin {
      margin-bottom: 0;
    }

    &.card-title--icon {
      font-weight: bold;
      font-size: 36px;
      line-height: 45px;
      text-align: center;
      text-transform: capitalize;
      color: #243447;
      margin-bottom: 24px;
    }
  }

  .card-avatar {
    display: flex;
    padding-top: 14px;
    margin-bottom: 12px;
  }

  .card-avatar-info {
    padding-top: 4px;
  }

  .card-avatar-image {
    margin-right: 16px;
  }

  .card-avatar-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    color: #192a3e;
    margin-bottom: 4px;
  }

  .card-avatar-position {
    font-size: 14px;
    line-height: 100%;
    color: #192a3e;
    margin-bottom: 9px;
  }

  .card-avatar-company {
    font-size: 12px;
    line-height: 100%;
    color: #707683;
    margin-bottom: 12px;
  }

  &.card--sq {
    border: none;

    .card-link {
      font-weight: bold;
      font-size: 14px;
      line-height: 134.77%;

      &:after {
        content: '';
        width: 47px;
        height: 15px;
        // background: url('../images/link-long-arrow.svg') no-repeat right;
        display: inline-block;
        position: absolute;
        top: 3px;
        right: -55px;
      }
    }

    .card-inner {
      padding: 29px 10px 48px 29px;
    }

    .card-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #707683;
      margin-bottom: 26px;

      ul {
        padding: 24px 0 0 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: #243447;

        li {
          margin-bottom: 7px;

          &:before {
            content: '•';
            font-weight: 500;
            font-size: 13px;
            line-height: 1;
            color: #14c1a2;
            position: absolute;
            margin-left: -9px;
            margin-top: 5px;
          }
        }
      }
    }

    .card-title {
      margin-bottom: 3px;
    }
  }

  .card-client-title-col {

    &.card-client-title-col--self-center {
      align-self: center;
    }
  }
}
