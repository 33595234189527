@import './colors';
@import './mixins';

section.green-v2 {
  padding: 132px 0 200px;
  position: relative;
  margin-bottom: 105px;

  @include bp(mobile) {
    padding: 75px 0 120px;
    margin-bottom: 70px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    // background: url('../images/bg/1.svg') repeat-x center;
    height: 499px;
    z-index: 0;

    @include bp(mobile) {
      background-size: 100%;
      background-position: top;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // background: url('../images/bg/2.svg') repeat-x center;
    height: 499px;
    z-index: 0;

    @include bp(mobile) {
      background-size: 100%;
      background-position: bottom;
    }
  }

  .green-inner {
    background: #14c1a2;
  }

  .green-v2-row {
    display: flex;
    justify-content: space-between;
  }

  .green-v2-form-holder-col {
    width: 687px;
    margin-right: 30px;

    @include bp(mobile) {
      width: auto;
      margin-right: 0;
    }
  }

  .green-v2-form-title {
    margin-bottom: 19px;

    h2{
      font-size: 46px;
      line-height: 50px;
      color: #fff;

      @include bp(tablet) {
        font-size: 36px;
        line-height: 40px;
      }

      @include bp(mobile) {
        font-size: 30px;
        line-height: 38px;
        text-align: center;
      }
    }
  }

  .manager-col {
    padding-top: 44px;
    width: 226px;
    margin-right: 45px;

    .manager-avatar {
      text-align: center;
      margin-bottom: 8px;
    }

    .manager-name {
      p{
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }
    }

    .manager-position {
      p{
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 8px;
        color: #fff;
      }
    }

    .manager-text {
      margin-bottom: 16px;
      p{
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
      }
    }

    .manager-call-button-holder {
      text-align: center;
    }
  }

  .form-terms {
    margin-top: -50px;
    margin-bottom: 50px;

    @include bp(mobile) {
      margin: 0;
      padding-top: 16px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 1;
      text-align: right;
      color: #fff;

      @include bp(mobile) {
        text-align: center;
      }

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
